export const launchDarklyFlagVariants = {
    CRO_912_HOMEPAGE_HEADING: {
        VARIANT_1: {
            title: 'COMPARE AIRPORT PARKING',
            subtitle: 'Find & Compare 300+ Airport Car Parks Across the UK',
        },
        VARIANT_2: {
            title: 'COMPARE & SAVE ON AIRPORT PARKING',
            subtitle: 'Find the best prices at 28 Airports Nationwide',
        },
        VARIANT_3: {
            title: 'SAVE ON AIRPORT PARKING TODAY',
            subtitle: 'Book with confidence at 300+ Airport Car Parks Across the UK',
        },
    },
};
